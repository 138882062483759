export const faqs = [
  {
    title: "Who pays for home health services?",
    url: "who_pays_for_services",
    body: `
    <p>HealthView Home Health services may be paid for by private insurance, Medicare, and Medicaid.  Services can also be paid for directly by the patient or the patient’s family, which is considered self-pay.</p>
    <p>Medicare pays for a variety of skilled services for in-home care. Our staff will be able to answer any questions regarding what services Medicare will pay for and what is not covered. HealthView Home Health will also notify you of the cost of services in writing before starting care.</p>
    `
  },
  {
    title: "Who is eligible for Home Health & Hospice?",
    url: "who_is_eligible",
    body: `
    <p>To qualify for HealthView Home Health care services, you or your loved one should be under the care of a doctor who prescribes a skilled care service to improve, maintain, prevent or slow a health condition. These services are provided by skilled professionals and may be on a part-time or intermittent basis.</p> 
    <p>For example: You have a fall that results in a hospital stay or surgery. After a 3-day hospital stay, you are discharged, but you are not completely healed from your injury. In your discharge papers, your doctor can note that you are currently homebound and need short-term skilled care to aid in your full recovery. </p>
    <p>Your discharge papers will also include a Plan of Care indicating to HealthView Home Health what services and equipment you will need during your care.</p>
    <p>To be considered homebound – you are unable to leave home because of an injury, an illness, or leaving takes great effort such as using a cane, wheelchair, walker, crutches or you need special transportation and the help of another person.</p>
    <p>For more information on home health coverage, please visit <a href="https://www.medicare.gov/" class="">medicare.gov</a>.
    `
  },
  {
    title: 'Areas of Service?',
    url: 'areas_of_service',
    body: `
    <p>With an office in Rocky Mount, HealthView Home Health & Hospice serves 10 counties in the surrounding area. Those counties include <strong>Edgecombe, Franklin, Halifax, Johnston, Nash, Vance, Wake, Warren, Wayne, and Wilson</strong>.</p>
    <p>If you have any question as to whether you or your loved one is included in our service area, please call us at <a href="tel:1-252-462-2687">(252) 462-2687</a>.</p>
    `,
    go_to_another_page_title: '',
    go_to_another_page_url: '',
    img: 'img/theme/HealthView_ServiceArea.png',
    img_alt: 'Service Areas Map - Edgecombe, Franklin, Halifax, Johnston, Nash, Vance, Wake, Warren, Wayne, and Wilson Counties',
    img_title: 'Service Areas in Eastern NC - HealthView'
  },
  {
    title: "How can I contact HealthView Home Health &amp; Hospice",
    url: "how_can_i_contact",
    body:`
    <p class="display-4">If it is a medical emergency please call <a href="tel:1-252-462-2687">(252) 462-2687</a>.</p>
    `,
    go_to_another_page_title: 'Contact Us',
    go_to_another_page_url: 'contact'
  },

]
