<template>
	<div>
		<HeroSmall :page_title="page_title" :leadline="leadline" />
		<div class="container py-lg ">
			<base-input placeholder="Search frequently asked questions..." addon-left-icon="fas fa-search" v-model="faq_search"></base-input>
			<section class="section">
				<div class="container">
					<card class="mb-5" shadow v-for="i in filtered_faqs" :key="i.url" :id="i.url">
						<template slot="header">
							<h3 class="d-none d-lg-block h4 text-primary text-uppercase" v-html="i.title"></h3>
							<h3 class="d-block d-lg-none h6 text-primary text-uppercase" v-html="i.title"></h3>
						</template>
						<div v-if="i.body" class="description mt-3" v-html="i.body"></div>
						<div>
							<img :src="i.img" class="img-fluid" :alt="i.img_desc" :title="i.img_title">
						</div>
						<div v-if="i.go_to_another_page_url" class="description mt-3">
							<p>Go to <router-link :to="`/${i.go_to_another_page_url}`">{{ i.go_to_another_page_title }}.</router-link></p>
						</div>
					</card>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
	import HeroSmall from '../views/components/HeroSmall.vue'
	import {
		faqs
	} from '@/data/faqs.js'
	export default {
		name: 'about',
		metaInfo: {
			title: 'Frequently Asked Questions',
			meta: [
				{
					'description': 'Find answers to frequently asked questions about home health care provided by HealthView Home Health & Hospice in Nash County and the additonal nine counties we now offer service to.'
				}
			]
		},
		components: {
			HeroSmall
		},
		data: () => ({
			page_title: 'FAQ',
			leadline: 'HealthView Home Health &amp; Hospice',
			faq_search: '',
			faqs: faqs,

		}),
		computed: {
			filtered_faqs: function () {
				//   let self = this
				//   let faq_search  = this.faq_search.toLowerCase()

				//   return self.faqs
				//   .filter((item) => {
				//       return item.body.toLowerCase().includes(faq_search)
				//   })

				let faq_search = this.faq_search.toLowerCase()

				return this.faqs
					.filter((item) => {
						return item.title.toLowerCase().includes(faq_search) || item.body.toLowerCase().includes(faq_search)
					})
			}
		},
		methods: {
			goTo(path) {
				this.$router.push({
					path: path
				})
			},
			scrollFix: function (hash) {
				setTimeout(() => $('html, body').animate({
					scrollTop: $(hash).offset().top
				}, 1000), 1)
			}
		}
	};
</script>
<style>
</style>